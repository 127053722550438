.badge-item-container {
	min-width: 0;
	min-height: 0;
	height: 100%;
}


.badge-item-container .badge-item-footer {
	background: #EFF2F4;
	width: 100%;
	text-align: center;
	font-weight: bold;
	padding-top: 5px;
	padding-bottom: 5px;
	border-top: 1px solid #ccc;
	font-weight: bold
}

.badge-item-container .badge-item-wrapper {
	border-radius: 6px;
	border: 1px solid #ccc;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #575757;;
	box-shadow: 0px 0px 1px #777;
	overflow: hidden;
	background: #FAFAFA;
	height: 100%;
}

.badge-item-container .badge-item-wrapper .badge-item-content {
    padding: .5em;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.badge-item-container .badge-item-wrapper .badge-item-content > div {
	flex: 1 0 100%;
	text-align: center;
}


.reward .badge-item-container .badge-item-wrapper .badge-item-content {
	padding: 0;
}