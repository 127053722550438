.badges-page .badges {
	display: grid;
	grid-template-columns: auto auto auto;
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
}


.badges-page .overlay {
	margin: 0;
}

.badge-page .overlay .content {
	background: none;
}