.edit-group .group  .image img {
	max-width: 100%;
	height: auto;
}


.group-item.master .col {
	padding: 10px;
}


.group-item.master .body {
	padding: 5px;
	overflow-y: hidden;
	overflow-x: hidden;
}


.group-item.master .body {
	flex: 1 1 50%;
	min-width: 0;
	position: relative;
}

.group-item.master .body .footer {
	height: 26px;
	width: 100%;
	text-align: right;
	line-height: 26px;
    font-size: 13px;
    color: #acacac;
    display: flex;
    flex-direction: row;
}


.group-item.master .body .header {
	height: 20px;
	width: 100%;
	text-align: right;
	line-height: 20px;
    font-size: 13px;
    color: #acacac;
}

.group-item.master .header span.count {
	margin-right: 10px;
}

.group-item.master .header span.count:last-child {
	margin-right: 0px;
}


.group-item.master .body .footer, .group-item.master .body .header span.count i {
	margin-right: 5px;
}

.group-item.master .body .body-content .social-icon i {
	width: 22px;
	height: 22px;
	background-color: #acacac;
	position: absolute;
	left: 0px;
	top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 11px;
	color: white;
	font-size: 12px;
	line-height: 22px;
}


.group-item .footer {
	line-height: 20px;
	color: #acacac;
}

.group-item .footer .time {
	overflow: hidden;
}


.group-item.master .footer .time {
	font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    text-align: left;
    margin-left: 27px;
}

.group-item.master .body h1 {
	font-size: 20px;
    /*line-height: 24px;*/ 
    margin-left: 27px;
}

.group-item.master .body .body-content {
	overflow: hidden;
	position: relative;
	word-wrap: break-word;
}