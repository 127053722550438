.page-error.container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	flex-wrap: wrap;
}

.page-error.container .logo {
	width: 50%;
	max-width: 90%;
	height: 200px;
	flex-basis: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.page-error.container h1 {
	flex-basis: 100%;
	text-align: center;
	font-size: 24px;
	font-weight: bold;
}

.page-error.container > .content {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}


.page-error.container .logo img {
	max-height: 100%;
	max-width: 100%;
	width: 100%;

}



@media(min-width: 992px) {
	.page-error.container .logo i {
	font-size: 500px;

	}
}



@media(min-width:)