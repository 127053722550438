.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#app {
  height: 100vh;
  position: relative;
}


iframe {
  width: 100vw;
  height: 100vh;
}


.page {
  padding-top: 70px;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
  overscroll-behavior: contain;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #575757;
}

.page .overlay > .content {
    background: transparent;
    padding: .5em;
}


.container-fluid {
  padding: 0;
}



html::webkit-scrollbar, body::webkit-scrollbar, div::webkit-scrollbar {
  display: none !important;
  width: 0 !important
}


::-webkit-scrollbar {
    width: 0px !important;
    background: transparent; /* make scrollbar transparent */
    display: none !important;
}

.frame-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
  overscroll-behavior: contain;
}



.page .btn, .my-profile.content .btn {
    padding: .375em .75em;
    font-size: 1em;
    line-height: 1.5;
    border-radius: .25em;
}


/* 16px @ 320px increasing to 21px @ 1024px */
@media (min-width: 320px) {
  .page, .my-profile.content {
    font-size: calc(1rem + ((1vw - 3.2px) * 0.7102));
    /* Where: * 0.7102 = 100 * font_Size_Difference / viewport_Width_Difference */
  }
}
/* Prevent font scaling beyond this breakpoint */
@media (min-width: 1024px) {
  .page, .my-profile.content {
    font-size: 21px;
  }
}