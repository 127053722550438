.campaign-content .header {
	padding: 15px;
	display:flex;
	flex-direction: row;
}

.campaign-content hr {
	margin: 0;
}



.campaign-content .header .title {
	font-size: 1.5em;
	display: flex;
	flex-grow: 1;
}

.campaign-content .body {
	padding: 15px;
}

.campaign-content .footer {
	text-align: center;
	padding: 15px;
}

.campaign-content .header .counters {
	display: flex;
	justify-content: flex-end;
	color: #acacac;
}

.campaign-content .header span.count {
	margin-right: 10px;
}

.campaign-content .header span.count:last-child {
	margin-right: 0px;
}

.campaign-content .header span.count i {
	margin-right: 5px;
}