.picker.btn i.fa {    			
	opacity: 0;				
}
.picker.btn.active i.fa {				
	opacity: 1;				
}

.picker {
	box-shadow: 0px 0px 3px #777;

}


.picker.btn.btn-light i {
	color: #007bff;
	text-shadow: none;
}