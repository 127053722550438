.radio-display-selector {
	display: flex;
	flex: 1 0 auto;
	justify-content: center;
}

.display-selector {
	box-shadow: 0px 0px 3px #777;
	display :flex;
	flex-direction: column;
	align-items: center;
}

.preview {
	position: relative;
	width: 20em;
	height: 15em;
	max-width: 100%;
}

.display-selector .preview > img {
		border-radius: 0;
		object-fit: cover;
		height: 100%;
		max-width: 100%;
}

.display-selector .preview {
	box-shadow: 0px 0px 3px #777;
	flex: 1 0 auto;
	display: flex;

}

.display-selector .preview.default img {

	flex: 0 1 50%;
	width: 50%;
}

.display-selector .preview.image-text {
	color: white;
	position: relative;
}

.display-selector  .preview-content, .display-selector .preview.image-text .preview-text {
	padding: 0 .5em;
	margin: .5em 0;
	width: 50%;
	text-align: left;
	white-space: pre-wrap;
	overflow-wrap: break-word;
	overflow: hidden;
}

.display-selector .preview.image-text .preview-text {
	width: 100%;
}

.display-selector  .preview-content .preview-text {
	font-size: .75em;
}

.display-selector .preview.image-text .preview-text {
	position: absolute;
	bottom: 0em;
	text-align: left;
}

.display-selector .preview.image-text img, .display-selector .preview.image-only img {
	flex: 0 1 100%;
}



.radio-display-selector > div {
	padding: .5em;
	flex: 1 1 0px;
}