



.overview {
	width: 100%;
	color: #575757;
	display: flex;
	justify-content: center;
}

.overview .wrapper {
	max-width: 100%;
	width: 100%;
}

.overview > .wrapper > a {
	text-decoration: none;
	color: #575757;
}

.overview h1, .overview h2 {
	font-weight: bold;
	color: #575757;
	margin: 0;
}


.overview h1 {
	font-size: 2em;
	font-weight: 700;
}

.overview h2 {
	font-size: 1.25em;
	font-weight: 700;
}

.overview .infos > div {
	flex-shrink: 0;
	flex-grow: 1;
}


.overview .infos .avatar {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.overview .infos .avatar img {
    height: 8em;
    width: 8em;
    border-radius: 50%;
    border: 2px solid #c9c9c9;
    box-shadow: 0 0 1px rgba(0,0,0,.25);
    object-fit: cover;
}

.overview .infos {
	display: flex;
	flex-wrap: wrap-reverse;
}

.overview .infos .user, .overview .infos .avatar {
	/*flex-basis: 50%;*/
	align-items: center;
}


.text-button {
	background: none;
	border: none;
	font-style: italic;
	color: #C9C9C9;
}


.overview .networks {
	display: flex;
	flex-wrap: wrap;
	margin-top: .33em;
}

.overview .networks .network {
	margin-left: .33em;
	color: #C9C9C9;
}

.overview .networks .network:first-of-type {
	margin-left: 0;
}


.overview .group-infos .follows {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: .33em;
}


.overview .group-infos .follows .group-avatars {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1em;
}

.overview .group-infos .follows img {
	width: 2em;
    height: 2em;
    margin-left: -0.66em;
    border-radius: 50%;
    border: 1px solid white;
}


.overview .primary-group {
	margin-top: .33em;
}


.overview .primary-group .group-name, .overview .last-badge .badge-name {
	margin-left: .33em;
}


.overview .last-badge {
	display: flex;
	max-width: 100%;
	align-items: center;
	margin-top: .5em;
	margin-bottom: .5em;
}

.overview .last-badge img {
	flex-shrink: 1;
	width: 2.5em;

}

.overview .last-badge h1 {
	flex-grow: 1;
	flex-basis: 0;
}


.overview .stats {
    flex-wrap: wrap;
    margin: 1em -0.66em .5em -0.66em;
   	display: flex;
   	white-space: nowrap;
   	padding: 0 0.33em;
   	background: rgba(0,0,0,0.01);
}

.overview .stats .stat  {
	flex-basis: 0;
	flex-grow: 1;
	justify-content: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	text-transform: capitalize;
	padding: .33em;
}

.overview .stats .stat.view-all {

}



.overview .stats .stat button {
	padding: 0;
}


.overview .stats .stat .value {
	font-weight: 700;
	font-size: 1.25em;
}

.overview .stats .stat .name {
	font-size: 1.25em;
}


.overview .avatar > a.btn {
	margin: .5em auto;
	font-size: .8em;
}


.overview .profile-actions {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.overview .profile-actions button {
	margin: 1em;
}