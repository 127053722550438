

.share-overlay {
	opacity: 0;
	display: flex;
	position: absolute;
	top: 5px;
	left: 5px;
	bottom: 5px;
	right : 5px;
	background: white;
	z-index: -1;
	align-items: center;
	justify-content: center;
	transition: 0.3s ease;
}


.share-overlay.active {
	display: flex;
	z-index: 9;
	opacity: 1;
	background-color: #FFFFFFCC;
	
}

.share-overlay .content {
	position: relative;
	max-width: 100%;
	max-height: 100%;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.featured .share-overlay .content, .s-featured .share-overlay .content  {
	grid-template-columns: auto auto auto;
}




.share-overlay .content .network  {
	text-align: center;
	display: inline-block;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-basis: 25%;
	margin-top: 15px;
    margin-bottom: 15px;

}

.share-overlay .content .network .wrapper:hover {
	opacity: 1;
}

.share-overlay .content .network .wrapper {
	opacity: 0.6;
	transition: 0.3s ease;
	cursor: pointer;
}

.share-overlay .content .network i {
	font-size: 1em;
	color: white;
}


.featured .share-overlay .content {
	max-width: 80% !important;
}

.featured .share-overlay .content  .network {
	flex-basis: 50%;
	font-size: 2em;
}

@media(min-width: 576px) {

	/*
	.share-overlay .content .network {
		padding: 10px;
	}
*/



	.featured .share-overlay .content {
		max-width: 100% !important;
	}

	.featured .share-overlay .content .network {
		flex-basis: 25%;
	}

	.s-featured .share-overlay .content {
		max-width: 80% !important;
	}

		.s-featured .share-overlay .content .network {
		flex-basis: 50%;
		
	}


	.share-overlay .content {
		grid-template-columns: auto auto auto auto;

	}



}


@media(min-width: 768px) {

.share-overlay .content {

}
	.s-featured .share-overlay .content .network {
		flex-basis: 50%;
	}

}