


.wall-item-edit {
	position: absolute;
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	top: 5px;
	left: 5px;
	z-index: 30;
	display:flex;
	align-items: center;
	justify-content: center;
	font-size: 2em;
	box-shadow: none;
	flex-direction: column;
}



.wall-item-edit.selected {
	box-shadow: 0px 0px 3px #007bff;
}

.wall-item-edit .picker {
    margin: 0;
    position: absolute;
    bottom: 15px;
    left: 15px;
    /* transform: scale(1); */
    width: 20px;
    height: 20px;
    border-radius: 3px;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.wall-item-edit .icons {
	display:flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}


.wall-item-edit.hidden, .wall-item-edit.combined {
	background: rgba(255,255,255,0.8);
}


.wall-item-edit.combined .master {
	color: #FAB600;
	text-align: center;
}

.wall-item-edit {
	 text-shadow: 1px 1px #777;
}

.wall-item-edit .group-number {
	font-size: 0.4em;
}



