.edit-group {
}


.edit-group > .header {
	display: flex;
	flex-direction: row;
	padding: 25px 15px 15px 15px;
	flex-wrap: wrap;
}

.edit-group > .header .title {
	font-size: 1.5em;
}

.edit-group > .header .create {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.edit-group > .body {
	background: #ddd;
	padding-bottom: 15px;
}

.edit-group .children {
	background: #ddd;

}

.edit-group .controls {
	padding-top: 15px;
	text-align: center;
}


.edit-group .header h3 {
	margin: 0;
}

.edit-group hr {
	margin: 0;
}

.edit-group  .master {
	background: white;

}

.edit-group  .children {

}


.edit-group .validate {
	margin-left: 15px;
}

.edit-group .control input {
	margin-left: 5px;
}


.edit-group > .header {
	display: flex;
	flex-wrap: nowrap;
}

.edit-group > .header .title, .edit-group > .header .counters, .edit-group > .header .control {
	display: flex;
	align-items: center;
	flex-grow: 1;
}


.edit-group > .header .title {
	justify-content: flex-start;
}

.edit-group > .header .counters {
	justify-content: center;
}

.edit-group > .header .control {
	justify-content: flex-end;

}


.edit-group > .header .counters {
	color: #acacac;;
}

.edit-group > .header .counters span.count {
	margin-right: 10px;
}

.edit-group > .header .counters span.count:last-child {
	margin-right: 0px;
}


.edit-group > .header .counters span.count i {
	margin-right: 5px;
}