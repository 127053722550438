.page.leaderboard-page {
	padding: 70px .33em .33em .33em;
}

.leaderboard-page > .content {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
}


.leaderboard-page > .content > div {
	flex-basis: 33.3333%;
	flex-grow: 1;
	max-width: 33.3333%;
}

.leaderboard-page > .content .user-infos {
	max-width: 100%;
}

.leaderboard-page > .content .user-infos > .wrapper {
	max-width: 100%;
}


.leaderboard-page > .content .user-infos > .wrapper > .content {
	max-width: 100%;
}


.leaderboard-page > .content .user-infos > .wrapper > .content > .avatar {
	max-width: 100%;
	width: 4em;
    height: 4em;
    object-fit: contain;
}


.leaderboard-page .leaderboard-user {
  padding: .25em;
}



@media(min-width: 768px) {
  .leaderboard-page > .content > div  {
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media(min-width: 1024px) {
  .leaderboard-page > .content > div  {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }
}


@media(min-width: 1200px) {
  .leaderboard-page > .content > div  {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
}