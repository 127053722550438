.app-loader {
    display:        table;
    position:       fixed;
    top:            0;
    left:           0;
    width:          100%;
    height:         100%;
}

.app-loader i {
    visibility: hidden;
}

.app-loader .content {
    display:        table-cell;
    width:          100%;
    height:         100%;
    background:     #ccc;
    text-align:     center;
    vertical-align: middle;
}

.app-loader .content .logo {
    height: 2em;
    background: no-repeat center center;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    -webkit-backface-visibility: initial !important;
    -webkit-transform-origin: 50% 50%;
}



@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}