.navbar {
	position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background: #333132;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.5);
    color: #eee;
    z-index: 30;
    transition: .3s ease all;
    padding: 5px 15px;
    overflow: hidden;
}


.navbar .content {
	width: 100%;
	display: flex;
}

.navbar .content .page-title {
	margin: 0 auto;
}

.navbar .content .back {
    margin-right: -29px;
}