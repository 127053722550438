.content-list {
	background: #ddd;
	border-bottom: 1px solid grey;
}

.content-list img {
	max-width: 40px;

}


.content-list > .col {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 5px;
    padding-bottom: 5px;

}

.content-list > .col.infos {
	justify-content: flex-start;
	flex-direction: column;
	align-items: flex-start;
}

.content-list .bonus {
	background-color: #E5007E;
	transform: rotate(-40deg);
    font-weight: 600;
    line-height: 28px;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 40px;
    color: white;

    align-self: center;
}


.content-list a {
	display: inline;
}


.campaign .infos .date {
	padding-top: 0;
	font-size: 0.9em;
}