.edit-options {
	padding-top: 65px;
	padding-bottom: 5px;
	background: #e6e6e6;
	padding-left: 5px;
	padding-right: 5px;
}


.edit-options .label-col {
	display: flex;
	align-items: flex-end;
}

.edit-options .options {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-basis: 100%;
}

.edit-options .global-options {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-basis: 100%;
}

.edit-options label {
	text-transform: uppercase;
	margin-bottom: 0;
}

.edit-options hr {
	margin-top: 5px;
	margin-bottom: 5px;
}


.edit-options button {
	margin-top: 5px;

}

.edit-options button:not(:first-child) {
	margin-left: 15px;
}


@media(min-width: 576px) {
	.edit-options .options {
		justify-content: flex-start;
	}

	.edit-options .global-options {
		justify-content: flex-end;
	}
}