.component-loader {
	position: absolute;
	z-index: 21;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #FFFFFFCC;
	display: flex;
	justify-content: center;
	align-items: center;
}

.component-loader {
	width: 100%;
	height: 100%;
	/*min-height: 100px;*/
}

.component-loader.transparent {
	background-color: transparent;
}


.component-loader .logo {
	position: relative;
	height: 2em;
    background: no-repeat center center;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    -webkit-backface-visibility: initial !important;
    -webkit-transform-origin: 50% 50%;
    margin: 15px;
}