
.stats-page {
	padding-left: .5em;
    padding-right: .5em;
}


.stats-page .overview {
	display: flex;
	justify-content: center;
}

.stats-page .stats {
	display: flex;
	margin: 0 -.25em;

}

.stats-page .stats > div {
	flex: 1 0 33.3333%;
	padding: .25em;
}

.stats-page .overview .stat {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-basis: 33.3333%;
	width: 33.3333%;
	padding: .125em;
}


.stats-page .overview .stats-container {
	margin: 0 -.125em;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	flex: 1 0 100%;
}

.stats-page .title {
	font-size: 1.2em;
	font-weight: 700;
	margin-top: 1em;
}

.stats-page .overview .stat .value {
	font-size: 1.2em;
}

.stats-page .overview .stat .label {
	text-align: center;
	word-break: break-word;
}

.stats-page .overview .stat.first {
	height: auto;
	min-height: auto;
	max-height: auto;
}

.stats-page .overview .stat.second {
	height: auto;
	min-height: auto;
	max-height: auto;
}

.stats-page .overview .stat.third {
	height: auto;
	min-height: auto;
	max-height: auto;
}

.stats-page .overview .stat.fourth {
	height: auto;
	min-height: auto;
	max-height: auto;
}

.stats-page .overview .stat.disabled {
		height: auto;
	min-height: auto;
	max-height: auto;
}


.stats-page .overview .stat.first .ratio-content {
	background-color: #F27FBF;
}

.stats-page .overview .stat.second .ratio-content {
	background-color: #FDDB7E;

}

.stats-page .overview .stat.third .ratio-content {
	background-color: #81BCA1;
}

.stats-page .overview .stat.fourth .ratio-content {
	background-color: #2DA3D6;
}

.stats-page .overview .stat.disabled .ratio-content  {
	background-color: #EFF2F4;
}



.stats-page .overview .stat .ratio {
	position: relative;
	padding-top: 100%;
	width: 100%;
}

.stats-page .overview .stat .ratio-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: .25em;
	font-weight: 700;
}

.stats-page .stats .avatar {
	width: 4em;
    height: 4em;
    object-fit: cover;
    border-radius: 50%;
}

.stats-page .stats .badge-image {
	max-height: 4em;
}


.stats-page .stats .badge-name {
	text-align: center;
}

	.stats-page .stats .reward-image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

@media(min-width: 568px) {
	.stats-page .overview .stat {
		flex-basis: 25%;
		width: 25%;
	}	

	.stats-page .stats .avatar {
		width: 6em;
   		height: 6em;
	}


	.stats-page .stats .badge-image {
		max-height: 6em;
	}

}


@media(min-width: 1280px) {
	.stats-page .overview .stat {
		flex-basis: 12.5%;
		width: 12.5%;
	}	

	.stats-page .stats .avatar {
		width: 8em;
   		height: 8em;
	}


	.stats-page .stats .badge-image {
		max-height: 8em;
	}
}