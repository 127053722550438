/*.wall-item {
	padding: 5px;
	height: 100%;
}


.wall-item.default > .content {
	height: auto;
	position:  absolute;
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;
}

.wall-item > .content {
	box-shadow: 0px 0px 3px #777;
	height: 100%;
	padding: 5px;
	background: white;
	overflow: hidden;
}



.wall-item.default:before{
    content: "";
    display: block;
    padding-top: 75%;  /*What you want the height to be in relation to the width*/
/*}*/

.wall-item.edit .content .footer .options, .wall-item.edit .content .footer .share {
	display: none;
}

.wall-item.edit .campaign-tag {
	z-index: 31;
}


.component-overlay {
	justify-content: center;
}

.component-overlay .option {
	padding: .5em;
	text-align: center;
}

.component-overlay .option button {
	width: 100%;
}


.wall-item {
	/*padding: 5px;*/""
	height: 100%;
	width: 100%;
	overflow-x: scroll;
	scrollbar-width: none;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	scroll-snap-type: mandatory;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
}

.wall-item::-webkit-scrollbar { 
    display: none; 
}

.page-controls .previous, .page-controls .next {
	/*width: 24px;*/
	background: grey;
	color: white;
	top: calc(50% - 15px);
	position: absolute;
	opacity: 1;
	width: 20px;
    height: 30px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-align: center;
}


.page-controls .previous i, .page-controls .next i {
	transform: translateY(25%);
}

.page-controls .previous {
	left: 5px;
	border-radius: 0px 30px 30px 0px;
	text-align: left;
}

.page-controls .next {
	right: 5px;
    border-radius: 30px 0px 0px 30px;
    text-align: right;
}


.wall-item > .child {
	width: 100%;
	display: flex;
	flex-shrink: 0;
	padding: 5px;
	position: relative;
	scroll-snap-align: start;
}

.wall-item > .child > .content {
	box-shadow: 0px 0px 3px #777;
	height: 100%;
	background: white;
	overflow: hidden;
	display: flex;
	width: 100%;
}

/*.wall-item > .child .content:hover {
	transition: 0.2s;
	box-shadow: 0px 0px 6px #444;

}*/

.wall-item .content .footer .share {
	overflow: visible;
	max-height: 100%;
}


.wall-item .content .footer .options, .wall-item .content .footer .time, .wall-item .content .footer .share {
	flex-shrink: 0;
	flex-grow: 1;
	display: flex;
	align-items: center;
}

.wall-item .content .footer .options {
	justify-content: flex-start;
}

.wall-item .content .footer .time {
	justify-content: center;
}

.wall-item .content .footer .share {
	justify-content: flex-end;
}


.wall-item .content .footer i {
	font-size: 2em;
}

.wall-item .index {
	position: absolute;
	bottom: 5px;
	right: 10px;
	font-size: 2em;
	color: red;
	display: none;
}

 .campaign-tag .campaign-bonus {
	position: absolute;
    top: -11px;
    left: -40px;
    background: #E5007E;
    color: #fff;
    width: 100px;
    text-align: center;
    transform: rotate(-45deg);
    font-weight: 600;
    padding: 18px 0 6px 0;
    font-size: 20px;
    line-height: 20px;
}

.campaign-tag {
	position: absolute;
    top: 5px;
    left: 5px;
    width: 70px;
    height: 70px;
    overflow: hidden;
    z-index: 2;
}


.wall-container .item:before{
    content: "";
    display: block;
    padding-top: 75%;  /*What you want the height to be in relation to the width*/
}



.wall-page-grid .featured.edit:before {
	padding-top: 75%;
}

.wall-page-grid .s-featured.edit:before {
	padding-top: 75%;
}


.wall-page-grid .featured:before  {
	content: "";
    display: block;
	padding-top: 133.3333%;
}

.wall-page-grid .s-featured:before {
	content: "";
    display: block;
	padding-top: 100%;
}



.wall-page-grid .featured .s-featured .item {
	height: 100%;
}


.only-image .image-content .title, .only-image .image-content .time {
	color: white;
	font-weight: bold;
	text-shadow: 2px 2px 2px #000;
	font-size: 18px;
}

 .only-image .image-content .time {
 	padding-top: 15px;
 	padding-bottom: 5px;
 	font-size: 12px;
 	line-height: 26px;
 }

.only-image .image-content {
	display: flex;
	align-self: flex-end;
	align-items: flex-start;
	flex-direction: column;
	padding-left: 5px;
	padding-right: 5px;
	min-width: 100%;
}


@media(min-width: 576px) {
	.wall-page-grid .featured:before {
		padding-top: 75%;
	}

	.tv .wall-page-grid .featured:before {
		padding-top: 74.9%;
	}

	.wall-page-grid .s-featured:before {
		padding-top: 150%;
	}

	.tv .wall-page-grid .s-featured:before {
		padding-top: 149.9%;
	}
}



.featured .wall-item, .s-featured .wall-item, .item .wall-item {
	height: auto;
	position:  absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;	
}


.s-featured .wall-item > .child > .content {
	display: flex;
	flex-direction: column;
}

.item .wall-item > .child > .content {
	display: flex;
	flex-direction: row;
}


.featured .wall-item > .child > .content {
	flex-direction: column;
}

.wall-item .body {
	padding: 5px;
	overflow-y: hidden;
	overflow-x: hidden;
}

.wall-item .image {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 50%;
	position: relative;
	overflow:hidden;
}

.wall-item .body {
	flex: 1 1 50%;
	min-width: 0;
	position: relative;
}

.wall-item > .child.only-image .image {
	flex: 0 1 100%;
	min-height: 100%;
}

.wall-item > .child.only-image .body {
	flex: 0 0 0%;
	max-width: 0;
	padding: 0;
}

.image {
	overflow: hidden;
	background-position: center center;
	background-size: cover;
}

.image img {
	  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}


.wall-item .content .body h1, .wall-item .content .body .item-author {
	font-size: 20px;
    /*line-height: 24px;*/ 
    margin-left: 27px;
}

 .wall-item .content .body .item-author {
 	color: #acacac;
 }

.wall-item .content .body p {
    margin-left: 27px;
    font-size: 13px;
    font-weight: 400;   
}

.wall-item .content .body .body-content {
	max-height: calc(100% - 46px);
	min-height: calc(100% - 46px);
	height:calc(100% - 46px);
	overflow: hidden;
	position: relative;
	word-wrap: break-word;
}

.wall-item .content .body .body-content:after {
	content: "";
    width: 100%;
    position: absolute;
    bottom: 0px;
    height: 15px;
    background: linear-gradient(rgba(255,255,255,0) 0%, #ffffff 100%);
}

.wall-item .content .body .footer {
	height: 26px;
	width: 100%;
	text-align: right;
	line-height: 26px;
    font-size: 13px;
    color: #acacac;
    display: flex;
    flex-direction: row;
}


.wall-item .content .body .header {
	height: 20px;
	width: 100%;
	text-align: right;
	line-height: 20px;
    font-size: 13px;
    color: #acacac;
}

.wall-item .content .body .header span.count {
	margin-right: 10px;
}

.wall-item .content .body .header span.count:last-child {
	margin-right: 0px;
}



.wall-item .content .body .footer, .wall-item .content .body .header span.count i {
	margin-right: 5px;
}

.wall-item .content .body .body-content .social-icon i {
	width: 22px;
	height: 22px;
	background-color: #acacac;;
	position: absolute;
	left: 0px;
	top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 11px;
	color: white;
	font-size: 12px;
	line-height: 22px;
}


.wall-item .content .body .body-content .social-icon i.fa-youtube {
    background-color: transparent;
    color: #FF0000;
    font-size: 1.2em;
}

.wall-item .content .body .body-content .social-icon.s1  i {
	top: 32px;

}



.wall-item .content .body .body-content .social-icon.s2  i {
	top: 59px;

}



.wall-item .content .body .body-content .social-icon.s3  i {
	top: 86px;

}



.wall-item .content .footer {
	line-height: 20px;
	color: #acacac;
}

.wall-item .content .footer .time {
	overflow: hidden;
}


.wall-item .content .footer .time {
	font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
}

.wall-item .content .footer .share {
    text-align: right;
}


.wall-item .pin {
	position: absolute;
    bottom: -4px;
    left: -33px;
    background: #009FE3;
    color: #009FE3;
    width: 80px;
    text-align: center;
    transform: rotate(45deg);
    font-weight: 600;
    padding: 6px 0 14px 0;
    font-size: 30px;
}

.wall-item .pin i {
	position: absolute;
    top: -160%;
    left: calc(50% - 12px);
}

.network-footer {
	background: #ddd;
	text-align: center;
	padding: 15px;
}

.network-footer button {
	margin: 5px;
}

button i {
	padding-left: 5px;
}


@media(min-width: 576px) {

	.wall-item .content .footer i {
		font-size: 1.6em;
	}


	.featured .wall-item > .child > .content {
		flex-direction: row;
	}

}