
.update-notification {
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  background-color: #333132;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  text-decoration: none;
}

.update-notification button {
    margin-left: .5em;
    color: white;
    text-decoration: underline;
    background: none;
    border: none;
}

.update-notification .close {
    color: white;
    opacity: 1;
    margin-left : 1em;
}

.update-notification button:hover {
  text-decoration: underline;
  color: white;
}