.tab-list {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	margin: .5em -0.33em;
	flex-wrap: wrap;
}

.tab-list > .header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	max-width: 100%;
	flex-wrap: wrap;
	flex-basis: 100%;
	flex-grow:	1;
	white-space: nowrap;
}

.tab-list > .items {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	flex-basis: 100%;
	flex-grow: 1;
	min-height: 6em;
}

.tab-list > .header > .category {
	flex-grow: 1;
	color: #c9c9c9;
	padding: .33em;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-basis: 0;
}

.tab-list > .header > .category > .category-title {
	font-size: 1.2em;
}


.tab-list > .header > .category.selected {
	font-weight: 700;
    color: #575757;
    text-decoration: underline;
}

.tab-list > .header > .category.full-list {
	justify-content: flex-end;
	flex-grow: 0;
	margin-left: auto;
}


.tab-list > .items > .item {
	flex-basis: 33.3333%;
	position: relative;
}


.tab-list > .items > .item > .item-wrapper {
	padding-top: 100%;
	position: relative;
}

.tab-list > .items > .item > .item-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: .33em;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: .33em;
}

.tab-list > .items > .item > .item-content > .feed {
	background-color: rgb(60, 90, 153);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2em;
    border-radius: 6px;
}

.tab-list > .items .no-result {
	max-width: 80%;
    margin: 0 auto;
    text-align: center;
}


.tab-list > .items > .item >  .item-content > img {
	height: 100%;
    width: 100%;
    border-radius: .33em;
    object-fit: cover;
}


@media(min-width: 768px) {
	.tab-list > .items > .item {
		flex-basis: 25%;
	}
}

@media(min-width: 1024px) {
	.tab-list > .items > .item {
		flex-basis: 16.6667%;
	}
}


@media(min-width: 1200px) {
	.tab-list > .items > .item {
		flex-basis: 12.5%;
	}
}