.master .fa.fa-star {
	color: #FAB600;
}



.group .infos .network {

}


.group .network i {
    width: 22px;
    height: 22px;
    background-color: #acacac;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 11px;
    color: white;
    font-size: 12px;
    line-height: 22px;
}


.group-item .time {
	overflow: hidden;
		line-height: 20px;
	color: #acacac;
		font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    text-align: left;
}


.group-item span.count {
	margin-right: 10px;
	color: #acacac;
	display: inline-block;
}

.group-item.master span.count:last-child {
	margin-right: 0px;
}


.group-item span.count i {
	margin-right: 5px;
}

.group-item:not(.master) .fa.fa-star {
	cursor: pointer;
}