.overlay {
	background: #333333F0;
	z-index: 30;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	animation: fadein 0.3s;
	align-items: center;
	justify-content: center;
	display: none;
}

.overlay.active {
	display: flex;
}


.overlay > .content {
	position: relative;
	background: white;
	max-height: 100%;
	max-width: 80%;
	min-width: 50%;
	overflow-y: auto;
	overflow-scrolling: touch;
	-webkit-overflow-scrolling: touch;
	max-width: 750px;
	background: transparent;
    padding: .5em;
}

.overlay.clean > .content {
	min-width: 0;
}

.overlay > .content > .content-wrapper {
	background: white;
	overflow: hidden;

	position: relative;
}

.overlay.rounded > .content > .content-wrapper {
	border-radius: 6px;
}


.overlay > .content > .close {
	position: absolute;
	top: 0;
    right: 0;
    padding-top: .2em;
    padding-right: .5em;
    opacity: 1;
    font-size: 1.8em;
    z-index: 20;
}


.overlay.clean > .content {
	height: 480px;
	width: 320px;
	overflow: hidden;
	min-width: auto;
	border: none;
}



.overlay iframe {
	height: 500px;
	width:	100%;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}



@media(max-width: 967px) {
	.overlay > .content {
		max-width: 100%;
		width: 100%;
	}
}
