#telex {
	position: absolute;
	bottom: 0;
	height: 60px;
	width: 100%;
	z-index: 9;
	background: #e6e6e6;
	box-shadow: 0px -1px 5px #808080;
	overflow: hidden;

}

#telex .telex-content {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;	
	height: 100%;
	font-size: 1.5em;
	animation: slide 360s linear infinite;
	transform:translateX(100vw);
}

#telex .telex-content p {
	margin: 0;
}

#telex .telex-content.single {
	transform:translateX(0);
	animation: none;
	justify-content: center;
	width: 100%;
}

#telex .item {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	max-height: 100%;
	white-space: pre;

}

#telex .telex-content:not(.single) .item:after {
	content: " | ";
	margin-left: 5px;
	margin-right: 5px;
}


@keyframes slide {
 0%   { transform: translateX(100vw); }
 100% { transform: translateX(-100%); }
}


#telex .owner-logo, #telex .logo {
	width: 10%;
	height: 100%;
	display: inline-block;

}

#telex .owner-logo {
	background-color: #333132;
	padding: 5px;
}

#telex .logo {
	padding: 5px;
	font-size: .8em;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 9px;
	overflow: hidden;
}

#telex .logo a {
	text-decoration: none;
	color: #212529;
}

#telex .logo img {
	max-width: 80%;
	margin-top: -10px;
}


#telex .background {
	background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

#telex .row {
	position:relative;
	height: 100%;
}


#telex .slider {
	overflow: hidden;
}

#telex .item br {
    content: ' ';
}

#telex .item br:after {
    content: ' ';
}



@media(max-height: 479px) {
	#telex {
		height: 0;
	}
}




@media(max-width: 991px) {

	.col-xs-0 {
		flex: 0 0 0 ;
		max-width: 0px;
	}

	#telex .owner-logo, #telex .logo {
		padding: 0px;
	}




}

