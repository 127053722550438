#main-menu {
	position: absolute;
    top: 0;
    width: 100%;
    height: 60px;
    background: #333132;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.5);
    color: #eee;
    z-index: 30;
    transition: .3s ease all;
    padding: 5px 15px;
    overflow: hidden;
}

#main-menu.inactive {
	height: 0;
	padding: 0;

}

#main-menu .logo {
	margin: 2px 0px;
	height: auto;
	cursor: pointer;
	max-height: 100%;
}

#main-menu img.logo {
	max-width: 162px;
	min-width: 0;
	margin-right: 14px;
	width: auto;
	flex-grow: 0;
	object-fit: contain;
}

#main-menu > .row {
	max-height: 100%;
	flex-wrap: nowrap;
}

#main-menu > .row > .col-6 {
	white-space: nowrap;
}


#breadcrumb {
	padding: 0px 30px 0px 0px;
    text-transform: uppercase;
    font-size: .8em;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 1;
}

#breadcrumb > span.text {
	display: none;
}


#filters-menu .close, #language-menu .close {
	width: 100%;
	color: white;
	text-align: right;
	padding-right: 15px;
	padding-top: 5px;
	opacity: 1 !important;
	font-size: 1.8em;
}

.fa-times-thin:before {
	content: '\00d7';
}

#filters-menu .close i, #language-menu .close i {
	cursor: pointer;
}



#breadcrumb .refresh-button {
	margin-left: 5px;
	min-width: 13px;
}

#main-menu .owner-logo {
	flex-shrink: 1;
	display: flex;
	align-items: center;
	max-height: 100%;
	min-width: 0;
}


#main-menu .controls {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-grow: 2;
	height: 46px;
	flex-shrink: 0;
}


#main-menu .spacer {
	display: inline-block;
    width: 1px;
    height: 18px;
    margin: 2px 14px -4px 14px;
    background: #aaa;
}

#main-menu .controls i {
	cursor: pointer;
}


#main-menu .controls a {
	text-decoration: none;
	color: white;
}



#filters-menu, #language-menu {
	background: #333132;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    top: 0;
    height: 0;
    z-index: 20;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    transition: 0.3s;
    max-height: 100%;
    overflow-y: auto;
    overflow-scrolling: touch;
	-webkit-overflow-scrolling: touch;
	transform: translateZ(0px);
	perspective: 1000;
}


#filters-menu.active, #language-menu.active {
	padding: 65px 0px 5px 0px;
	height: auto;
	transition: 0.3s;
}

#filters-menu .category, #language-menu .category  {
	width: 100%;
	border-bottom: 1px solid #716f70;
	padding: 10px 15px 10px 15px;

}

#filters-menu .footers {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 15px;
}

@media (min-width: 768px) {

	#filters-menu .footers  {
		margin-bottom: 0;
	}

}
#filters-menu .footer-link {
	display: inline-block;
	text-align: center;
}
#filters-menu .footer-link a {
	color: white;
	text-decoration: underline;
	padding: 0 .33em;

}


.category .row div:first-child label  {

	word-wrap: break-word;
	display: block;
	
}

.category label {
	font-size: 18px;
	margin-right: 15px;
	margin-bottom: 0px;
}



#filters-menu .network-filters .networks {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}


#filters-menu .network-filters .network {
	background: #fff;
    color: #333132;
    font-size: 12px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    line-height: 24px;
    margin: 0 15px 0px 0;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    opacity: 0.4;
    transition: .3s ease all;
    position: relative;
}

#filters-menu .network-filters .network .count {
	position: absolute;
	top: -30%;
	right: -30%;
	font-size: .5em;
	border-radius: 0;
}

#filters-menu .network-filters .network-toggle {
	margin-right: 15px;
	cursor: pointer;
}

#filters-menu .network-filters .network-toggle i {
	margin-right: 5px;
}

#filters-menu .network-filters .network.active {
	opacity: 1;
}




#filters-menu .network-filters .networks .network:last-child {
	margin: 0;
}


#filters-menu .channels {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}

#filters-menu .channel {
	text-align: center;
	margin-right: 15px;
	display: inline-block;
	opacity: 0.4;
	cursor: pointer;
}

#filters-menu .channel.active {
	opacity: 1;
}

#filters-menu .channel .channel-image {
	background-position: center center;
	background-size: cover;
	display: none;
}


#filters-menu .filters, #language-menu .filters {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
}


#filters-menu .filters .filter,  #language-menu .filters .filter {
	margin-right: 15px;
	opacity: 0.4;
	cursor: pointer;
}

#filters-menu .filters .filter:last-child,  #language-menu .filters .filter:last-child {
	margin-right: 0px;
}

#filters-menu .filters .filter.active, #language-menu .filters .filter.active {
	opacity: 1;
	transition: 0.3s;
}


#filters-menu .search .input-group {
	width: auto;
}

#filters-menu .reset .filters {
	justify-content: flex-end;
}

 .filter-separator {
	margin-right: 15px;	
	opacity:  1;
}


@media(max-width: 767px) {
	/*#main-menu .spacer {
		margin-left: 5px;
		margin-right: 5px;
	}*/

}

@media(min-width: 768px) {

	#filters-menu .channels {
		justify-content: flex-start;
	}

	#filters-menu .network-filters .networks {
		justify-content: flex-start;
	}

	#filters-menu .filters {
		justify-content: flex-start;
	}

	#filters-menu .filters .filter {
		background: #636363;
	    min-width: 120px;
	    height: 40px;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    padding: 0px 10px;
	    margin: 5px 15px 5px 0px;
	    overflow: hidden;
	}


	#filters-menu .channel {
		max-width: 120px;
		width: 120px;
		text-align: center;
		margin-right: 15px;
		display: inline-block;
		opacity: 0.4;
		cursor: pointer;
	}


	#filters-menu .channel .channel-image {
		width: 120px;
		height: 80px;
		background-position: center center;
		background-size: cover;
		display: block;
	}

	#main-menu {
   	 	padding: 5px 30px;
   	}



}

@media(min-width: 1024px) {
	#breadcrumb > span.text {
		display: inline;
		margin-right: 15px;
	}

	#breadcrumb {
		padding: 0px 30px;
	}

}


.btn.global-search {
	border-left: 1px solid black;
}

.filters.active i, .language.active i {
	color: #007bff;
}
