.campaign {
	display: flex;
	flex-direction: row;
}

.campaign .name {
	font-size: 1.2em;
}

.campaign .date {
	    color: #acacac;
	    padding-top: 15px;
}


.campaign .visual {
	position: relative;
	display: flex;
	flex-shrink: 1;
	overflow: hidden;
	justify-content: center;
}

.campaign .visual img {
	max-width: 100%;
	object-fit: contain;
	object-position: center center;

}


.campaign .campaign-tag {
	top: 0px;
	left: 0px;
}