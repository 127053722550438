.overlay .reward {
	padding: .5em;
	color: #575757;
}

.overlay .reward .content {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}


.overlay .reward .content .share {
	margin-top: 1em;
}

.overlay .reward .content > div {
	flex: 1 0 100%;
}


.overlay .reward .points {
	font-size: 1.2em;
    font-weight: 700;	
    text-align: center;
     margin: .5em 0;
}

.overlay .reward .title {
	font-size: 1.2em;
    font-weight: 700;
    text-align: center;
    background: rgba(0,0,0,.2);
    margin: .5em -.5em -.5em -.5em;
    padding: .5em;
}

.overlay .reward .body {
	text-align: center;
}

.overlay .reward img {
    max-width: 100%;
    max-height: 8em;
}



.overlay .reward .share  {
	text-align: center;
}

.overlay .reward .share .share-title {
	color: #c9c9c9;
	text-decoration: underline;
}

.overlay .reward .share .share-icon {
	font-size: 2em;
}


.overlay .component-overlay.share-overlay .networks {
	display: grid;
	grid-template-columns: auto auto auto;
}

.overlay .content .share > .network-footer {
	background: none;
}