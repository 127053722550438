.edit-campaign-items {
}

.edit-campaign-items .hr {
	margin: 0;
}

.edit-campaign-items > .header {
	display: flex;
	flex-direction: row;
	padding: 15px;
	flex-wrap: wrap;
}

.edit-campaign-items > .header .title {
	font-size: 1.5em;
}

.edit-campaign-items > .header .create {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.edit-campaign-items > .body {
	padding-bottom: 15px;
	background: #ddd;
	overflow-x: hidden;
}


.edit-campaign-items .campaign-item {
		background: #ddd;

}

.edit-campaign-items .controls {
	margin-top: 15px;
	text-align: center;
}

.edit-campaign-items .next {
	margin-left: 15px;
}

.edit-campaign-items .header, .edit-campaign-items .campaign {
	padding: 15px;
}

.edit-campaign-items .header h3 {
	margin: 0;
}

.edit-campaign-items .header hr {
	margin: 0;
	margin-top: 5px;
}

.master .group.content-list {
	background: white;
}