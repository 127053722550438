.create-post {
	padding: 1em;
	text-align: center;
	color: #575757;
}

.create-post form {
	text-align: left;
}

.create-post .hidden {
	display: none;
}

.create-post > h2 {
	text-align: center;
	font-size: 1.5em;
}

.create-post .image-control  {
	text-align: center;
}

.create-post i.large {
	font-size: 5em;

}

.create-post .image-control .post-image i {
	display: block;
}

.create-post .post-image label {
	display: block;
}

.create-post .post-image {
	overflow:hidden;

}



.create-post form input.btn {
	margin-top: 1em;
}

.create-post form textarea.body {
	height: 10em;
}

.create-post form textarea.title {
	height: 5em;
}

.create-post form .error {
	margin-top: 1em;
}

.create-post form label.counter-label {
	display: flex;
	margin-bottom: 0;
}

.create-post form label.counter-label span.label-title {
	margin-bottom: .5rem;
}

.create-post form label.counter-label .char-counter {
	flex-grow: 1;
	text-align: right;
	font-size: 0.75em;
	margin-top: auto;
	opacity: 0;
}

.create-post form label.counter-label .char-counter.fade-in {
	opacity: 1;
	transition-duration: 0.3s;
}

.create-post form label.counter-label .char-counter.fade-out {
	opacity: 0;
	transition-duration: 0.3s;
}