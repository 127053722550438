html {
  overflow-y: auto;
}


.my-profile {
  font-family: 'Roboto' !important;
}


.my-profile .overlay > .content {
  background: transparent;
  padding: 2em .5em;
}

.my-profile .overlay > .content .badge-container {
  background: transparent;
  padding: 0;
}

.my-profile .overlay > .content .close {
  top: 1.1em;
  right: .5em;
  padding: 0;
}



.profile-panel {
	background: #eff2f4;
    box-shadow: 0px 0px 3px #777;
    padding: 30px;
}

#navbar-logo {
	max-height: 46px;
    margin-left: 30px;
    margin-top: 2px;
    border: none;
    vertical-align: middle;
    cursor: pointer;
}

.container-fluid.content {
	margin-top: 3em;
}

.my-user {
  text-align: center;
}

.card.profile {
	background: #eff2f4;
	margin-bottom: 1em;
	border-radius: 0px;
  flex: 1 1 auto;
  height: calc(100% - 1em);
}

.card-anim {
  padding-bottom: 1em;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
}
.card.profile .user-infos {
	text-align: center;
}


img.avatar {
  max-height: 10em;
}

.spinner {
    height: 2em;
    /*background: url('/images/socialpa-ico.svg') no-repeat center center;*/
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
 	-webkit-backface-visibility: initial !important;
    -webkit-transform-origin: 50% 50%;
}

.badge {
	padding: 1em;
  max-width: 100%;
  overflow: hidden;
}

.badge img {
	max-height: 100%;
	max-width: 100%;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}


.card-body .networks .network {
  padding-top: 15px;
}


.networks .network .user-info img {
	width: 48px;
	height: 48px;
}






.summary .summary-item .summary-item-content {
  background: white;
  padding: 1em;
  margin: 1em;
  border-radius: 2px;
  text-align: center;
}

.summary .summary-item .summary-item-content .summary-item-value {
  font-size: 1.25em;
}

.activity-feed img {
  max-width: 100%;
  max-height: 100%;
}


.activity-feed {
  max-height: 50vh;
  overflow-y: scroll;
  padding-right: 1em;
}


@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}


#app > .content > .row {
  padding-top: 75px;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
  padding-left: 10px;
  padding-right: 10px;
}

#app > .content.my-profile > .row {
  padding-left: .66em;
  padding-right: .66em;
}

#app > .content {
  max-width: 100%;
}


.my-user .controls {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.my-user .controls .control {
    flex: 0 0 100%;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
}

.coverflow.coverflow-badges .content > .item {
    display :flex;
    justify-content: center;
}

.coverflow.coverflow-badges .content > .item .badge img {
    max-height: 30vh;
    max-height: 100px;
}

.coverflow.coverflow-leaderboard .content > .item {
    display :flex;
    justify-content: center;
}

.coverflow.coverflow-leaderboard .content > .item .user-infos {
   max-width: 100%;
   max-height: 100%;
   padding-top: 2.33em;
}

.coverflow.coverflow-leaderboard .content > .item .user-infos img {
  height: 4em;
  width: 4em;  
  margin-top: -2em;
  transition: .3s;
}



.view-mode-controls {
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    align-items: center;
    justify-content: flex-start;
    color: #575757;
    margin-top: 1em;
}


.view-mode-controls .title {
    flex-grow: 1;
    text-align: left;
    font-size: 1.25em;
    font-weight: 700;
}


.view-mode-controls .controls {
    justify-self: flex-end;
    font-size: 1.25em;
}

.view-mode-controls i {
  margin-left: .33em;
}

.view-mode-controls i.inactive {
  color: #C9C9C9;
}

.channels {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px;
  justify-content: center;

}


.coverflow.coverflow-channels .content > .item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.coverflow.coverflow-campaigns .content > .item {
    display: flex;
    justify-content: center;
    align-items: center;
}



.coverflow.coverflow-channels .content > .item .channel {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    font-size: 0.8rem;
}


.coverflow.coverflow-campaigns .content > .item .campaign {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    font-size: 0.8rem;

}


.coverflow.coverflow-channels  .channel img {
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
  max-height: 100px;
  border-radius: 6px;
}


.coverflow.coverflow-campaigns  .campaign img {
  max-width: 100%;
  max-height: 100%;
  flex-grow: 1;
  max-height: 100px;
  border-radius: 6px;
}

.coverflow.coverflow-channels  .channel .channel-name {
  font-weight: bold;
      color: #575757;
      white-space: nowrap;
}


.coverflow.coverflow-campaigns  .campaign .name {
      font-weight: bold;
      color: #575757;
      text-align: center;
      line-height:  1rem;
      max-height:   2rem;
      overflow: hidden;
}


.coverflow-wrapper {
    width: 100%;
    min-width: 100%;
    flex-basis: 100%;
}


.coverflow .reward {
    max-width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid rgba(0,0,0,0.2);
}


.coverflow .reward.locked {
  opacity: 0.5;
}

.coverflow .reward .ratio {
    position: relative;
    /* padding-top: 100%; */
    /* max-width: 100%; */
    height: 100%;
}

.coverflow .reward .ratio .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.coverflow .reward .ratio .content .title {
    position: absolute;
    bottom: 0;
    background: rgba(240,240,240,0.9);
    text-align: center;
    padding: .33em;
    width: 100%;
}

.coverflow .reward img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 16em;
}

.overlay .component-overlay.share-overlay .networks {
  display: grid;
  grid-template-columns: auto auto auto;
  flex: 1 1 100%;
}

.overlay .content .share > .network-footer {
  background: none;
}

@media(min-width: 992px) {
  .coverflow-wrapper {
    width: 50%;
    min-width: 50%;
    flex-basis: 50%;
  }
}


@media (min-width: 1024px) {
    .coverflow .username {
      flex-grow: 0;

    }

    .coverflow .user-infos {
      height: auto;
    }
}

