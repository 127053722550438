.wall-loader .loader {
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
}

.wall-loader .loader.loading {
	display: flex;
}

.wall-loader .content {
	background: transparent;
	box-shadow: none;
	width: 100%;
}

.wall-loader .content .logo {
    height: 2em;
    background: no-repeat center center;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    -webkit-backface-visibility: initial !important;
    -webkit-transform-origin: 50% 50%;
}

.wall-item.wall-loader .content:hover {
	box-shadow: none;
}