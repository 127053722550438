.campaign-item .infos {
	display: block;
}


.campaign-item span.count {
	margin-right: 10px;
	color: #acacac;
}

.campaign-item span.count i {
	margin-right: 5px;
}

.campaign-item .social-icon i {
	width: 22px;
	height: 22px;
	background-color: #acacac;;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 11px;
	color: white;
	font-size: 12px;
	line-height: 22px;
}