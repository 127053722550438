.edit-profile-page {
	padding-left: 10px;
	padding-right: 10px;
	color: #575757;
	background: rgba(0,0,0,0.02);
}


.edit-profile-page .networks {
	width: calc(100% + 20px);
	display: flex;
	flex-wrap: wrap;
	margin-top: 15px;
	margin: 15px -10px 0px -10px;
}


.edit-profile-page .networks .network {
	flex-basis: auto;
	display: flex;
	padding: 10px;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
}

.edit-profile-page .networks .network-connect {
	margin-left: auto;
}

.edit-profile-page .networks .network .network-name {
	color: #c9c9c9;

}


.edit-profile-page .networks .network .network-info {
	flex-basis: auto;
	display: flex;
	align-items: center;
}


.edit-profile-page .networks .network .network-info .network-icon {
  margin-right: .66em;
  font-size: 1.25em;
  height: 100%;
  border-radius: 50%;
  color: white;
  padding: .33em;
  width: 2.25em;
  height: 2.25em;
  display: flex;
  justify-content: center;
  align-items: center;
}



.edit-profile-page .networks .network.odd {
	background: white;
}



.edit-profile-page .networks .network .user-info {
	flex-grow: 1;
	display: flex;
	justify-content: flex-end;
	padding: 10px;
	flex-shrink: 0;
}

.edit-profile-page .networks .network .user-info .user {
	text-align: right;
}

.edit-profile-page .networks .network .user-info .username {
	font-weight: 700;
}

.edit-profile-page .networks .network .user-info img {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	align-self: center;
}


.edit-profile-page > .avatar {
	width: 100%;
	display: flex;
	justify-content: center;
}

.edit-profile-page > .avatar > .avatar-wrapper > img {
	min-height: 0;
	height: 8em;
	width: 8em;
	border-radius: 50%;
	border: 2px solid #C9C9C9;
	box-shadow: 0px 0px 1px rgba(0,0,0,0.25);
	object-fit: cover;
}


.edit-profile-page > .avatar > .avatar-wrapper {
	position: relative;
}

.edit-profile-page > .avatar > .avatar-wrapper > .camera {
    position: absolute;
    bottom: .66em;
    right: .33em;
    border-radius: 50%;
    background: grey;
    color: #fff;
    width: 2em;
    height: 2em;
	display: flex;
    align-items: center;
    justify-content: center;
}

.edit-profile-page > .user-status {
	width: 100%;
}

.edit-profile-page > .user-status > label {
	font-weight: 700;
}

.edit-profile-page > .user-status > textarea {
	height: 140px;

}

.edit-profile-page > .user-status > textarea::placeholder {
	color: #c9c9c9;
}

.edit-profile-page > .group-controls {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	white-space: nowrap;
	
}

.edit-profile-page > .group-controls > div {
	margin-top: 15px;
}


.edit-profile-page .networks-title {
	font-size: 1.250em;
	font-weight: 700;
	margin-top: 15px;
	text-align: center;
}

.edit-profile-page > .group-controls .label {
	font-size: 1.250em;
	font-weight: 700;
}

.edit-profile-page > .group-controls > .primary-group {
	flex-basis: 50%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.edit-profile-page > .group-controls > .primary-group > .title {
	color: #c9c9c9;
	text-decoration: underline;
	padding: .375rem .75rem;
}

.edit-profile-page > .group-controls > .primary-group > .title  a {
	color: #c9c9c9;
}



.edit-profile-page > .group-controls > .secondary-groups {
	flex-basis: 50%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.edit-profile-page > .group-controls > .invite-a-friend, .edit-profile-page > .group-controls > .share-content {
	flex-basis: 50%;
	display: flex;
	align-items: center;
	flex-direction: column;
}



.edit-profile-page .change-list {
	background: rgba(0,0,0,0.025);
}

.edit-profile-page .change-list {
	padding: 0.5em 0;
}

.edit-profile-page .overlay .header {
	padding: 1em;
}

.edit-profile-page .overlay .footer {
	padding: 0.5em;
	text-align: center;
}

.edit-profile-page .overlay .header .title {
	font-size: 1.2em;
}

.edit-profile-page .change-list .list-item i {
	margin-right: 0.5em;
}

.edit-profile-page .change-list .list-item {
	font-weight: bold;
	cursor: pointer;
}

.edit-profile-page .change-list .list-item {
	padding: 0.5em;
	border-bottom: 1px solid rgba(0,0,0,0.25);
}

.edit-profile-page .change-list .list-item:first-child {
	padding-top: 0;
}

.edit-profile-page .change-list .list-item:last-child {
	padding-bottom: 0;
	border-bottom: none;
}

.edit-profile-page .change-list .list-item.delete {
	text-align: center;
}

.edit-profile-page .change-list .hidden {
	display: none;
}

.user-status .save-container {
	width: 100%;
	margin-top: .5em;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.user-status .save-container button {
	margin-left: .66em;
}