.component-overlay {
	opacity: 0;
	display: flex;
	position: absolute;
	top: 5px;
	left: 5px;
	bottom: 5px;
	right : 5px;
	background: white;
	z-index: -1;
	align-items: center;
	justify-content: center;
	transition: 0.3s ease;
}

 {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}


.component-overlay.active {
	display: flex;
	z-index: 9;
	opacity: 1;
	background-color: #FFFFFFCC;
	
}


.overlay-content .component-overlay,  .content-wrapper .component-overlay {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}