.channels-page > .content {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
}


.channels-page > .content > div {
	flex-basis: 33%;
	max-width: 33%;
}



.channels-page img {
	border-radius: 6px;
	width: 100%;
}


.channels-page .channel {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: .5em;
}

.channels-page .channel .channel-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	/*border: 1px solid #ccc;*/
	border-radius: 6px;
	height: 100%;
	width: 100%;
}

.channels-page .channel-name {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: flex-end;
}


@media(min-width: 768px) {
  .channels-page > .content > div  {
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media(min-width: 1024px) {
  .channels-page > .content > div  {
    flex-basis: 16.6667%;
    max-width: 16.6667%;
  }
}


@media(min-width: 1200px) {
  .channels-page > .content > div  {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
}