.overlay .badge-container > .badge-wrapper > .badge-content {	
	display: flex;
    justify-content: center;
    align-items: center;
}

.overlay .badge-container > .badge-wrapper > .badge-content .badge-points {
	font-size:	 1.2em;
	font-weight: 700;
}


.badge-container {
	min-width: 0;
	min-height: 0;
}


.badge-container .badge-footer {
	background: #EFF2F4;
	width: 100%;
	text-align: center;
	font-weight: bold;
	padding-top: 5px;
	padding-bottom: 5px;
	border-top: 1px solid #ccc;
	font-weight: bold
}

.badge-container .badge-wrapper {
	border-radius: 6px;
	border: 1px solid #ccc;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #575757;;
	box-shadow: 0px 0px 1px #777;
	overflow: hidden;
	background: #FAFAFA;
	height: 100%;
}

.badge-container .badge-wrapper .badge-content {
    padding: .5em;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.badge-container .badge-wrapper .badge-content > div {
	flex: 1 0 100%;
}

.badge-container .badge-wrapper .badge-content .badge-name {
	font-size: 1.2em;
    font-weight: 700;
    text-align: center;
    /* width: 100%; */
    background: rgba(0,0,0,0.2);
    margin: .5em -.5em;
    padding: .5em;
}

.badge-container .badge-wrapper .badge-content .badge-name.no-share {
	margin-bottom: -.5em;
}

.badge-container .badge-wrapper .badge-content img {
	max-width: 100%;
	max-height: 8em;
}

.badge-container .badge-wrapper .badge-content .share  {
	text-align: center;
}

.badge-container .badge-wrapper .badge-content .share .share-title {
	color: #c9c9c9;
	text-decoration: underline;
}

.badge-container .badge-wrapper .badge-content .share .share-icon {
	font-size: 2em;
}