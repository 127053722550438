.user-infos {
    color: #575e67;
    display: flex;
    align-items: center;
    width: 100%;
    transition: .3s;
}


.user-infos > div.wrapper {
   display: flex;
   height: 100%;
   flex-direction: column;
   align-items: center;
  background-color: #FAFAFA;
   border-radius: 6px;
    padding-right: 0;
    padding-left: 0;
    overflow: visible;
    margin-top: 0 !important;
    border: 1px solid #DDD;
    flex-grow: 1;
    box-shadow: 0px 0px 1px rgba(0,0,0,0.25);
}

.user-infos .wrapper .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: .33em;
    padding-top: 0;
    overflow: visible;
}





.user-infos .points {
  color: #8A8A8A;
}

.leaderboard.row {
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.user-infos .points, .user-infos .username {
   font-weight: bold;
   margin-bottom: .33em;

}

.user-infos .username {
  text-align: center;
  flex-grow: 1;
  min-height: 3em;
}

.user-infos .rank {
    font-weight: bold;
    width: 100%;
    text-align: center;
    background-color: #EFF2F4;
    border-top: 1px solid #DDD;
    border-radius: 0px 0px 6px 6px;
}

.featured .user-infos  {
   padding-top: 0px !important;
}


.coverflow .item  .user-infos > .wrapper > .content > img {
    margin-top: -1.6667em;
}


.coverflow .item.featured  .user-infos > .wrapper > .content > img {
    margin-top: .33em;
}


.user-infos .wrapper .content img {
  margin-top: .33em;
}


