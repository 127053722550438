

.item-overlay.inactive {
	display: none;
}


.item-overlay.active {
	background: rgba(0, 0, 0, .75);
	z-index: 10000;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	animation: fadein 0.3s;
	margin-left: -15px;
	margin-right: -15px;
}

.item-overlay {
	overscroll-behavior: contain;
	overflow-scrolling: touch;
	-webkit-overflow-scrolling: touch;
	transform: translate3d(0,0,0);
	perspective: 10000;
}

.item-overlay .main {
	margin: 2em .5em;
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	flex-direction: column;
	max-width: 750px;
	border-radius: 6px;
	overflow: hidden;
}


.item-overlay .component-overlay {
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-y:scroll;
}


.item-overlay.active .main {
	box-shadow: 0px 0px 3px #333;
}

.item-overlay .body .content {
	background: white;
	position: relative;
	padding-top: 15px;
}

.item-overlay .row {
	height: 100%;
	justify-content: center;
	margin-left: 15px;
	margin-right: 15px;
	overflow-y: scroll;
}


.item-overlay .image {
	max-width: 100%;
	display: flex;
	justify-content: center;
	background-color: white;
	width: 100%;
}

.item-overlay .image img {
	max-width: 100%;
	max-height: 100%;
	width: 100%;
	height: 100%;
	object-fit: contain;
}


.item-overlay .header {
	background: white;
	text-align: right;
	color: #acacac;
}


.item-overlay .header span.count {
	margin-right: 10px;
}

.item-overlay .header span.count:last-child {
	margin-right: 0px;
}

.item-overlay .header span.count i {
	margin-right: 5px;
}


.item-overlay .body {
	padding: 15px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	width: 100%;

}

.item-overlay .overlay-content .footer {
	width: 100%;
}


.item-overlay .share-overlay {
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.item-overlay .share-overlay .content {
	max-width: 300px;
	align-items: center;
}

.item-overlay .share-overlay .content .network {
	font-size: 3em;
	position: relative;
	overflow: hidden;

}

 .network .network-content {
	position: relative;
	width: 60%;
	padding-top: 60%;
}

 .network .wrapper {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 10%;
	border: 1px solid #dedede;
	border-radius: 0.3em;
	display: flex;
	align-items: center;
	justify-content: center;
}



.item-overlay .overlay-content {
	position: relative;
    overflow-y: auto;
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
}



.item-overlay .overlay-content::webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}


.item-overlay .close {
	position: absolute;
	top: 0;
    right: 0;
    padding-right: 10px;
    color: white;
    opacity: 1;
    text-shadow: 2px 2px 2px #000;
    font-size: 1.8em;
    z-index: 10001;
}

.item-overlay .comments {
	font-size: 0.8em;
	font-weight: 400;
    color: #acacac;
    text-decoration: underline;
    cursor: pointer;
}


.item-overlay .social-icon i {
	width: 22px;
	height: 22px;
	background-color: #acacac;
	position: absolute;
	left: 0px;
	top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 11px;
	color: white;
	font-size: 12px;
	line-height: 22px;
}

.item-overlay .social-icon i.fa-youtube  {
	color: #FF0000;
	background: transparent;
	font-size: 1.2em;
}


.item-overlay .body-content {
	margin-left: 27px;
	word-break: break-word;
}

.item-overlay .content h1 {
	font-size: 20px;
    line-height: 24px;
}

.item-overlay .content p {
    font-size: 13px;
    font-weight: 400;   
}


.item-overlay .share-footer {
	padding: 0px 15px 15px 15px;
	font-size: 16px;
	display: flex;
	flex-direction: row;

}

.item-overlay .share-footer .time, .item-overlay .share-footer .share, .item-overlay .share-footer .options {
	display: inline-block;
	flex-shrink: 0;
	flex-grow: 1;
	overflow: hidden;
	display: flex;
}

.item-overlay .share-footer .options {
	justify-content: flex-start;
}

.item-overlay .share-footer .time {
	justify-content: center;
}

.item-overlay .share-footer .share {
	justify-content: flex-end;
}

.item-overlay .footer .share-footer {
	width: 100%;
	text-align: right;
    color: #acacac;
}


.item-overlay .footer .time {
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    align-items: flex-end;
}

.item-overlay .video, .item-overlay .video iframe {
	max-width: 100%;
	max-height: 50vh;
}

.item-overlay .footer .share {
    text-align: right;
}

.item-overlay .share-footer i {
    font-size: 2em;
    margin-right: 1px;
}

.item-overlay .campaign-tag {
	top: 0;
	left: 0;
}


.item-activities {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	background-color: #ddd;
}

.item-activity {
	display: flex;
	flex: 0 0 100%;
	align-items: center;
	padding: 15px;
    border-top: 1px solid #acacac;
}

.item-activity .avatar {
	display: flex;
	justify-content: center;
	align-items: center;
}

.item-activity .avatar img {
	max-width: 100%;
	width: 52px;
	border: 2px #fff solid;
    border-radius: 26px;

}

.item-activity .share-message {
	display: inline;
	align-items: center;
	justify-content: flex-start;
	padding-left: 15px;
}



.item-overlay .share-overlay .content {
		max-width: 80% !important;
		max-height: 100% !important;
		overflow-y: scroll;
		overscroll-behavior: contain;
		-webkit-overflow-scrolling: touch;

}

.item-overlay .share-overlay .content {
	
}

.item-overlay .share-overlay .content .network {
		flex-basis: 50%;
		font-size: 2em;
}


@media(min-width: 576px) {
	.item-overlay .share-overlay .content .network {
		flex-basis: 25%;
		font-size: 2em;
}

}	



@media(min-width: 768px) {
	.item-overlay .share-overlay .content {
		grid-template-columns: auto auto auto auto;
		max-width: 500px;
		max-height: 300px;
	}
}




@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}