.wall-wrapper {
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	max-height: 100vh;
}

.wall-container {
	background :#e6e6e6;
	overflow-y: scroll;
	overflow-scrolling: touch;
	overscroll-behavior-y: contain;
	-webkit-overflow-scrolling: touch;
	transform: translateZ(0px);
	z-index: 0;
	perspective: 1000;
	padding-top: 65px;
	position: relative;
	height: 100%;
	/*transition-duration: 0.3s;*/
}

.wall-container.edit {
	padding-top: 0px;
}

.wall-container.telex {
	padding-bottom: 65px;
}

.wall-container.no-menu {
	padding-top: 0;
}

.row.full {
	height: 100%;
}



.first, .second, .third {
	min-height: 100%;
	max-height: 100%;
	height: 50vh;
}


.pull-to-refresh {
	background: red;
	max-height: 130px;
}


.wall-page-grid {
	display: grid;
	width: 100vw;
	grid-template-columns: 100%;
	position: relative;
	align-items: flex-start;
}


.wall-page-grid .featured {
  	grid-row: span 2;
  	position: relative;
}

.wall-page-grid .s-featured {
	grid-row: span 2;
	position: relative;
}


.wall-page-grid .featured.edit, .wall-page-grid .s-featured.edit {
	grid-row: auto;
	grid-column: auto;
}


.wall-page-grid .item {
	position: relative;

}

@media(min-width: 576px) {
	.wall-page-grid {
		grid-template-columns: 50% 50%;
	}

	.wall-page-grid .featured {
		grid-column: span 2;
	}


	.wall-page-grid .s-featured {
	}
}


.wall-wrapper .wall-refresh {
	position: absolute;
	z-index: 10;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: rgba(230, 230, 230, 0.55);
	margin-left: -15px;
	margin-right: -15px;
	display: flex;
	align-items: center;
	justify-content: center;
	display: none;
	opacity: 0;
}

.wall-wrapper .wall-refresh.active {
	display: flex;
	opacity: 1;
	animation: fadein 0.3s;
}


.wall-wrapper .wall-refresh.inactive {
	display: flex;
	animation: fadeout 0.3s;
}

.wall-wrapper .wall-refresh.after-inactive {
	display: none;
	transition-delay: 0.31s;

}

.overlay iframe.login-frame {
	width: 320px;
	height: 480px;
	border: none;
}	

.wall-wrapper .wall-refresh .logo {
    height: 2em;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    backface-visibility: initial !important;
}



	.tv .wall-page-grid {
		display: table;
		table-layout: fixed;

	}

	.tv .wall-page-grid .item {
		display: table-cell;
		float: left;
		width: 100%;
	}

	.tv .wall-page-grid .s-featured {
		display: table-cell;
		float: left;
		width: 100%;
	}
	.tv .wall-page-grid .featured {
		display: table-cell;
		float: left;
		width: 100%;
	}


@media(min-width: 768px) {
	.wall-page-grid {
		/*grid-template-columns: 25% 25% 25% 25%;*/
		grid-template-columns: 33.3333% 33.3333% 33.3333%;
	}

	.wall-page-grid .featured {
		grid-column: span 2;

	}


	.wall-page-grid .s-featured {
	}

	.wall-page-grid .wall-item .content h1  {
		font-size: 0.9em;
	}
	.wall-page-grid .wall-item .content p  {
		font-size: 0.8em;
	}

	.wall-page-grid .featured .wall-item .content h1 {
		font-size: 1.1em;
	}

	.wall-page-grid .featured .wall-item .content p {
		font-size: 1em;
	}

	.wall-page-grid .s-featured .wall-item.content h1 {
		font-size: 1em;
	}

	.wall-page-grid .s-featured  .wall-item.content p {
		font-size: 0.9em;
	}

	.tv .wall-page-grid .item {
		width: 33.333333%;
	}

	.tv .wall-page-grid .s-featured {
		width: 33.333333%;
	}
	.tv .wall-page-grid .featured {
		width: 66.666666%;
	}
}



@media(min-width: 1200px) {
	.wall-page-grid {
		/*grid-template-columns: 20% 20% 20% 20% 20%;*/
		grid-template-columns: 25% 25% 25% 25%;
	}


	.tv .wall-page-grid .item {
		width: 25%;
	}

	.tv .wall-page-grid .s-featured {
		width: 25%;
	}
	.tv .wall-page-grid .featured {
		width: 50%;
	}
}

@media(min-width: 1900px) {
	.wall-page-grid {
		grid-template-columns: 16.6666666667% 16.6666666667% 16.6666666667% 16.6666666667% 16.6666666667% 16.6666666667%;
	}

	.tv .wall-page-grid .item {
		width: 16.6666666667%;
	}

	.tv .wall-page-grid .s-featured {
		width: 16.6666666667%;
	}
	.tv .wall-page-grid .featured {
		width: 33.3333333334%;
	}

}


@media (min-width: 992px) { 

	.col-ct {
		display: flex;
		flex: 1 0 25%;
		flex-wrap: wrap;
	}


	.col-ct-2 {
		display: flex;
		flex: 1 0 50%;
		flex-wrap: wrap;
	}

}


/*@media(min-width: 1920px) {*/

/*}*/


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}