.tree-select-item {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	border-bottom: 1px solid #ddd;
	padding: .5em;
	flex: 1 0 100%;
	align-items: center;
	transition-duration: .3s;
	height: auto;

}


.tree-select-item .title {
	flex-grow: 1;
	font-size: 1.2em;
	padding: 0 .4em;
}


.tree-select-item .visibility-control {
	justify-content: flex-start;
}

.tree-select-item .selection-control {
	justify-content: flex-end;
}

.tree-select-item.hidden {
	min-height: 0;
	height: 0;
	flex: 0 0 0px;
	overflow: hidden;
	padding: 0;
	border-bottom: 0px;
	max-height: 0;
}


.tree-select-item .picker {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .25em;
	margin-bottom: 0;
}

.tree-select-item.primary {
	font-style: italic;
}