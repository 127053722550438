.ephemeral-message {
	opacity: 0;
	transition-duration: 0.3s;
}

.ephemeral-message.active {
	opacity: 1;
}

.ephemeral-message .message {
	font-style: italic;
}