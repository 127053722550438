.share-thanks-content {
    padding: 0px;
}

.share-thanks-logo {
	width: 150px;
    padding: 0 25px 0 0;
    align-self: center;
}

.share-thanks-logo img {
	max-width: 100%;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}


.share-thanks-texte {
    width: calc( 100% - 150px);
}

.share-thanks-texte .message {
    font-size: 15px;
    font-weight: 400;
    color: #000;
    padding: 15px 0;
}

.share-thanks-texte .date {
    font-size: 14px;
    font-weight: 400;
    color: #acacac;
}


.share-thanks-content .content {
	width: 100%;
    border-bottom: 1px solid #acacac;
    padding: 25px;
    display: flex;
}


.overlay-featured {
    padding: 25px;
    width: 100%;
    min-height: 150px;
    overflow: hidden;
}

.overlay-featured .img-cont {
    float: left;
    width: 150px;
    height: 100%;
    position: relative;
}

.img-cont img {
	max-width: 100%;
}

 .overlay-featured h1 {
 	font-size: 1em;
}


.overlay-featured .txt-cont {
    float: left;
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 30px;
}


.overlay-featured .img-cont + .txt-cont {
    width: calc(100% - 160px);
    margin-left: 10px;
	word-break: break-word;
}


.overlay-featured .txt-cont .network-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 22px;
    height: 22px;
    border-radius: 12px;
    line-height: 22px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    background: #acacac;
}

.overlay-featured .timer {
    font-size: 14px;
    font-weight: 400;
    color: #acacac;
    margin-top: 10px;
}


.share-thanks-points {
    float: left;
    font-size: 1.3em;
    color: #000;
    width: 150px;
}


.overlay-collection tr {
    height: 70px;
    vertical-align: middle;
}


.overlay-collection {
    width: 100%;
}


.overlay-collection .user-pict {
    border: 2px #fff solid;
    border-radius: 26px;
    width: 52px;
    height: 52px;
    margin: 10px 20px 10px 0;
    background-size: cover;
    background-position: center center;
}

.overlay-collection td {
    background: #ddd;
    border-top: 1px solid #acacac;
}


.overlay-collection .with-user-pict {
    width: 52px;
}

.overlay-collection  td:first-child {
    padding-left: 25px;
}


.overlay-collection td:last-child {
    padding-right: 25px;
}


table.overlay-collection td .overlay-row-text {
    padding-right: 25px;
}