.edit-campaign {
}


.edit-campaign > .header {
	display: flex;
	flex-direction: row;
	padding: 15px;
	flex-wrap: wrap;
}

.edit-campaign > .header .title {
	font-size: 1.5em;
}

.edit-campaign > .header .create {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.edit-campaign > .body {
	background: #ddd;
	padding-bottom: 15px;
}

.edit-campaign .disclaimer  {
	padding: 15px;
	display: flex;
	flex-direction: row;
}

.edit-campaign .disclaimer img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	object-position: center center;
	height: 100%;
	width: 200px;

}

.edit-campaign .disclaimer .content {
 	padding-left: 15px;
}

.edit-campaign .campaigns {
	background: #ddd;

}

.edit-campaign .controls {
	padding-top: 15px;
	text-align: center;
}

.edit-campaign .next {
	margin-left: 15px;
}

.edit-campaign .header h3 {
	margin: 0;
}

.edit-campaign hr {
	margin: 0;
}