.user-container {
	
}


.user-container .user-wrapper .user-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #575757;
	padding: 1em;
	text-align: center;
	background: #FAFAFA;
}


.user-container .user-content > img {
	width: 8em;
	height: 8em;
	border-radius: 4em;
	overflow: hidden;
}

.user-container .user-content .username {
	font-weight: bold;
	text-align: center;
	font-size: 1.2em;
	/*color: ;*/
}


.user-container .user-content .member-since {
	color: #c9c9c9;
	font-size: 0.8em;
}


.user-container .user-content .stats .points {
	font-weight: bold;
	margin-top: .5em;
}

.user-container .user-content .stat-grid {
	display: grid;
	grid-template-columns: repeat(2,1fr);
}

.user-container .user-content .stat-grid .stat {
	flex: 1 1 0px;
	font-size: 1em;
	font-weight: bold;
	text-align: center;
	padding: .5em;
}


.user-container .user-content  .last-badge {
    font-weight: 700;
    text-align: center;
    /* width: 100%; */
    background: rgba(0,0,0,0.2);
    margin: .5em -.5em;
    padding: .5em;
    flex: 1 0 100%;
    width: calc(100% + 2em);
}

.user-container .user-content  .last-badge .badge-name {
	margin-left: 1rem;
	font-size: 1.2em;
}

.user-container .user-content  .last-badge img {
	width: 3em;
}

.user-container .user-content .networks {
	display: flex;
	font-size: 1.2em;
}

.user-container .user-content .networks .network {
	padding: 0 .33em;
}