.coverflow {
	position: relative;
	display: flex;
	flex-grow: 1;
	max-width: 100%;
	justify-content: center;
}

.coverflow .wrapper {
	display: flex;
	justify-content: center;
	align-items: stretch;
	flex-grow: 1;
	max-width: 100%;
}

.coverflow > .wrapper > .content::-webkit-scrollbar {
	   display: none;
}

.coverflow > .wrapper > .content {
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-grow: 1;
	max-width: 100%;
	flex-wrap: nowrap;
	overflow-x: scroll;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	/*scroll-snap-type: mandatory;*/
	/*scroll-snap-type: x proximity;*/
	scroll-behavior: smooth;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
}

.coverflow .content > .item {
	/*scroll-snap-align: center;
	scroll-snap-stop: always;*/
	align-items: center;
	transition-duration: .3s;
	padding: 0em .5em;
	-webkit-backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;
	transform: translateZ(0);
	-webkit-font-smoothing: subpixel-antialiased;
	filter: blur(0);
	flex-basis: 33.33%;
	flex-shrink: 0;
	height: 100%;
}

.coverflow .next {
	/*float: right;*/
	z-index: 10;
	min-width: 1em;
	margin-left: .33em;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.coverflow .previous {
	/*float: left;*/
	z-index: 10;
	min-width: 1em;
	margin-right: .33em;
	display: flex;
	align-items: center;
}

.coverflow .item.featured {
	transform: scale(1.1);
	z-index: 10;
}
  

 .coverflow .loader {
 	position: absolute;
 	top: 0;
 	left: 0;
 	bottom: 0;
 	right: 0;
 	background: #ccc;
 }

 .coverflow .error {
 	display: flex;
 	flex-grow: 1;
 	justify-content: center;
 	align-items: center;
 	flex-wrap: wrap;
 }

 .coverflow .error .message {
 	font-size: 1.2em;
 	flex: 1 0 100%;
 	text-align: center;
 }


  .coverflow .error button i {
 	padding: 0;
 }


@media(min-width: 1024px) {
	.coverflow .content > .item {
		flex-basis: 20% !important;
	}
}

